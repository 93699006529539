<template>
  <a-modal
    title="修改"
    :width="800"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="close"
    cancelText="关闭">
    <a-spin :spinning="confirmLoading">
      <a-form>
        <a-form-item label="会员到期时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-date-picker
            style="width: 100%"
            v-model="formData.vipOverTime"
            format="YYYY-MM-DD HH:mm:ss"
            allow-clear/>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { updateVipTime } from '@/api/biz/user'
export default {
  name: 'UpdateVipTimeForm',
  components: {

  },
  props: {

  },
  data() {
    return {
      formData: {
        vipOverTime: null
      }, // 表单数据
      visible: false, // 弹窗显示控制
      confirmLoading: false, // 提交后加载, 防止短时间内重复提交

      labelCol: { xs: { span: 24 }, sm: { span: 5 } }, // 表单项标签宽度配置
      wrapperCol: { xs: { span: 24 }, sm: { span: 16 } } // 表单项内容宽度配置
    }
  },
  methods: {
    /**
     * 外部方法, 外部调用此方法 打开编辑表单
     * @param {*} record 数据
     */
    audit(record) {
      this.formData = record
      this.confirmLoading = false
      this.visible = true
    },

    /**
     * 关闭表单, 触发 close 事件
     */
    close() {
      this.$emit('close')
      this.visible = false
    },
    /**
     * 提交
     */
    handleOk() {
      this.confirmLoading = true
      updateVipTime(this.formData).then(res => {
        if (res.success) {
          this.$message.success(res.message)
          this.close()
          this.$emit('ok')
        } else {
          this.$message.warning(res.message)
        }
      }).finally(() => {
        this.submitLoading = false
      })
    }
  }
}
</script>
<style lang='less' scoped></style>
